import * as React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, List } from 'antd';
import { iModel } from '@/state/models/plans';
import styled from 'styled-components';

import PricingHome from '../static/pricing-home.svg';
import PricingOffice from '../static/pricing-office.svg';
import PricingEnterprise from '../static/pricing-enterprise.svg';

const Wrapper = styled(Card)`
	height: 100%;
	padding-bottom: 500px;
	box-shadow: 0 4px 18px 0 rgba(0,0,0,.2);
	
	text-align: center;
	img {
		height: 170px;
		margin-bottom: 30px;
		overflow: hidden;
		width: 90%;
	}
	
	.ant-list {
		.ant-list-item-meta {
			text-align: left;
			align-items: center;
  			justify-content: center;
			
			b {
				color: black;
				display: block;
			}
			
			.value {
				float: right;
				font-size: 1.5rem;
				vertical-align: middle;
				line-height: .9;
			}
			
			.ant-list-item-meta-content {
				vertical-align: middle;
			}
		}
	}
	
	.description {
		color: rgba(0, 0, 0, 0.45);
		margin-bottom: 20px;
	}
	
	.footer {
		position: absolute;
		left: 20px;
		right: 20px;
		bottom: 20px;
		
		.ant-btn {
			margin-top: 20px;
			height: 60px;
			font-size:1.3rem;
			border-radius: 0;
			font-weight: 800;
			width: 100%;
		}
	}
`;

interface Props {
	plan: iModel;
	cycle: string;
	onClick: (plan: iModel) => void;
}

interface iPlanFeature {
	value: string | number | boolean;
	isBoolean: boolean;
	title: string;
}

const PricingCard: React.FunctionComponent<Props> = ({ plan, cycle, onClick, ...rest }) => {
	const media = () => {
		switch (plan.name.toLowerCase()) {
			case 'office':
				return PricingOffice;
			case 'enterprise':
				return PricingEnterprise;
			default:
				return PricingHome;
		}
	};

	const price = () => {
		let price = plan.price_monthly;

		if (price == 0) {
			return <div>
				<b className={'price'}>FREE</b>
				<span>Forever</span>
			</div>;
		}

		if (String(cycle) == 'bi-annually') {
			price = plan.price_bi_annually;
		}

		if (String(cycle) == 'annually') {
			price = plan.price_annually;
		}

		return <div>
			<b className={'price'}>{Number(price).toLocaleString('en-US', {
				currency             : 'USD',
				style                : 'currency',
				currencyDisplay      : 'symbol',
				minimumFractionDigits: 1,
			})}</b>
			<span>{cycle} (<b style={{
				color: '#ccc',
			}}>+VAT</b>)</span>
		</div>;
	};

	const features = () => {
		const features: iPlanFeature[] = [{
			title    : 'Lockdown mode',
			value    : plan.supports_lockdown_mode,
			isBoolean: true,
		}, {
			title    : 'Parental controls',
			value    : true,
			isBoolean: true,
		}, {
			title    : 'Max configurations',
			value    : plan.max_connections,
			isBoolean: false,
		}, {
			title    : 'Max custom rules',
			value    : plan.max_rules,
			isBoolean: false,
		}, {
			title    : 'Monthly queries',
			value    : plan.max_monthly_queries,
			isBoolean: false,
		}];

		return features;
	};

	const listDataItem = (item: iPlanFeature) => {
		const getCheckBoxColor = () => {
			if (item.isBoolean) {
				if (item.value === true) {
					return 'var(--color-accent)';
				} else {
					return '#ccc';
				}
			} else if (item.value == 0) {
				return '#ccc';
			} else {
				return 'var(--color-accent)';
			}
		};

		const value = (item.value != undefined && !item.isBoolean) && <span className={'value'}>
			{item.value == -1 ? 'Unlimited' : Number(item.value).toLocaleString()}
		</span>;
		const title = <b>{item.title} {value}</b>;

		return (
			<List.Item.Meta
				avatar={<Avatar
					size={'small'}
					style={{ backgroundColor: getCheckBoxColor() }}
					icon={<CheckOutlined/>}/>}
				title={title}

			/>
		);
	};

	return (
		<Wrapper
			{...rest}
			className={'card'}
			title={<b>{plan.name}</b>}
		>
			<img src={media()} alt={plan.name}/>
			<p className={'description'}>{plan.description}</p>
			<div className="footer">
				<List
					itemLayout="horizontal"
					dataSource={features()}
					renderItem={(item: iPlanFeature) => (
						<List.Item>{listDataItem(item)}</List.Item>
					)}
				/>

				{price()}

				<Button
					type={'primary'}
					block={true}
					size={'large'}
					onClick={(e: any) => {
						e.preventDefault();
						onClick(plan);
					}}
				>Subscribe</Button>
			</div>
		</Wrapper>
	);
};

export default PricingCard;
