import * as React from 'react';

import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { Row, Col, Switch, List, Divider } from 'antd';

import Content from '../content';

import Rules from './static/rules.svg';
import Devices from './static/devices.svg';
import RulesForm from './static/rules_form.png';
import StatsImg from './static/stats-logs.png';
import StatsCategoriesImg from './static/stats-categories.png';

const Wrapper = styled.div`

	.callout {
		h2 {
			text-align: center;
			font-size: 3.5rem;
			font-weight: 400;
		}
		
		p {
			text-align: center;
			font-size: 1.5rem;
			
			&.secondary {
				font-size: 1.3rem;
				color: #666;
			}
		}
		
		@media (max-width: 990px) {
			h2 {
				font-size: 1.5rem;
				font-weight: 800;
			}
			
			p {
				font-size: 1.2rem;
			}
		}
	}
	
	.features {
		padding: 80px 0 100px 0;
		
		&.tight {
			padding: 40px 0 60px 0;
		}
		
		h5 {
			font-size: 1.6rem;
			font-weight: 600;
		}
		
		p {
			margin-bottom: 30px;
			font-size: 1.1rem;
			color: #666;
			text-align: justify;
		}
		
		.devices {
			margin-bottom: 30px;
			
			.col {
				text-align: center;
				
				span {
					display: block;
					color: black;
					text-transform: uppercase;
					font-size: .9rem;
				}
				
				.icon {
					color   : var(--color-primary);
					font-size: 55px;
				}
			}
		}
		
		.icon-global {
			color   : var(--color-primary);
			font-size: 75px;
		}
		
		@media (max-width: 990px) {
			h5 {
				font-size: 1.4rem;
			}
			
			.location-icon {
				display: none;
			}
		}
	}
`;

interface Props {

}

interface State {

}

export default class Callout extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return (
			<Wrapper {...this.props}>
				<Content className="callout">
					<h2>Works on any device</h2>
					<p>
						Up and running with a simple change of your DNS server. No software to install!
					</p>
				</Content>

				<Content className="features">
					<Row>
						<Col md={24} lg={12} style={{ textAlign: 'left' }}>
							<img
								src={Devices} alt="Customizable DNS rules" width={'90%'}
								style={{ margin: '0 auto' }}
							/>
						</Col>
						<Col md={24} lg={12} style={{ marginTop: 40 }}>
							<Row className={'devices'}>
								<Col span={8} className={'col'}>
									<LegacyIcon type={'desktop'} className={'icon'}/>
									<span>Desktop</span>
								</Col>
								<Col span={8} className={'col'}>
									<LegacyIcon type={'tablet'} className={'icon'}/>
									<span>Tablet</span>
								</Col>
								<Col span={8} className={'col'}>
									<LegacyIcon type={'mobile'} className={'icon'}/>
									<span>Mobile</span>
								</Col>
							</Row>

							<p>
								Changing your DNS server takes only a minute. Just browse our list of
								locations
								and pick something that suits your needs. You're good to go in no time.
							</p>

							<Divider/>

							<h5>Over 50 locations to choose from</h5>
							<Row>
								<Col lg={5} md={0} className={'location-icon'}>
									<LegacyIcon type={'environment'} className={'icon-global'}
											theme={'twoTone'}
											twoToneColor="#2F57E4"/>
								</Col>
								<Col lg={19} md={24}>
									<p>
										It's entirely your decision wether to connect to a nearby
										server
										or
										a remote one in order to unlock content or bypass
										geo-restrictions. We're
										covering all major continents and we've only just started.
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Wrapper>
		);
	}
}

export class PornCallout extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return (
			<Wrapper {...this.props}>
				<Content className="callout">
					<h2>Over 30,000 porn websites blocked</h2>
					<p>
						We keep track and continuously check and update our list of adult websites
					</p>
				</Content>

				<Content className="features">
					<Row>
						<Col md={24} lg={12} style={{ textAlign: 'left' }}>
							<img
								src={Rules} alt="Customizable DNS rules" width={'90%'}
								style={{ margin: '0 auto' }}
							/>
						</Col>
						<Col md={24} lg={12} style={{ marginTop: 40 }}>
							<p>
								Whatever the reason is for blocking porn websites we have the right
								solution. We keep track of over 30k websites with adult content
								and you can block all of them with a single click.
							</p>

							<Divider/>

							<h5>Keep your children safe</h5>
							<Row>
								<Col lg={5} md={0} className={'location-icon'}>
									<LegacyIcon type={'stop'} className={'icon-global'}
											theme={'twoTone'}
											twoToneColor="#EDC8F0"/>
								</Col>
								<Col lg={19} md={24}>
									<p>
										Stay in control over the online experience of your children.
										With widespread access to the Internet, curious teens may
										accidentally or intentionally be exposed to thousands of
										pages
										containing material that is uncensored, sexually explicit,
										often
										inaccurate and potentially harmful.
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Wrapper>
		);
	}
}

export class SocialMediaCallout extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return (
			<Wrapper {...this.props}>
				<Content className="callout">
					<h2 style={{
						maxWidth : 1000,
						textAlign: 'center',
						margin   : '0 auto 30px auto',
					}}>Reclaim your time by blocking distracting websites</h2>
					<p>
						We keep track and continuously check and update our list of social media websites
					</p>
				</Content>

				<Content className="features">
					<Row>
						<Col md={24} lg={12} style={{ textAlign: 'left' }}>
							<img
								src={Rules} alt="Customizable DNS rules" width={'90%'}
								style={{ margin: '0 auto' }}
							/>
						</Col>
						<Col md={24} lg={12} style={{ marginTop: 40 }}>
							<p>
								With our DNS servers you can block all social media websites
								with a single click. Our list contains hundreds of social media
								websites.
							</p>

							<Divider/>

							<h5>Reclaim your time and be productive</h5>
							<Row>
								<Col lg={5} md={0} className={'location-icon'}>
									<LegacyIcon type={'clock-circle'} className={'icon-global'}
											twoToneColor="#EDC8F0"/>
								</Col>
								<Col lg={19} md={24}>
									<p>
										You can count on our DNS servers to filter out any social
										media
										websites and focus on the productive part of your day with a
										lot
										more time on your hands. Don't worry, you won't miss much.
									</p>
								</Col>
							</Row>

							<h5>Block social media at work</h5>
							<Row>
								<Col lg={5} md={0} className={'location-icon'}>
									<LegacyIcon type={'usergroup-delete'} className={'icon-global'}
											twoToneColor="#EDC8F0"/>
								</Col>
								<Col lg={19} md={24}>
									<p>
										Are your employees wasting too much time on social media
										websites?
										Use dnsadblock to block all those requests right at the DNS
										level.
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Wrapper>
		);
	}
}

export class AdsCallout extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return <Wrapper {...this.props}>
			<Content className="callout">
				<h2>Over 120,000 ad websites blocked</h2>
				<p>
					Break free of ads that are annoying, intrusive manipulative
				</p>

				<p className={'secondary'}>
					The ad business is getting out of hand. In order to be more relevant
					they also attempt at tracking your every step. What you do, what you
					like and what you shop. With <b>DnsAdBlock</b> you can completely deny
					their access with a single click.
				</p>
			</Content>

			<Content className="features">
				<Row>
					<Col md={24} lg={12} style={{ textAlign: 'left' }}>
						<img
							src={Rules} alt="Customizable DNS rules" width={'90%'}
							style={{ margin: '0 auto' }}
						/>
					</Col>
					<Col md={24} lg={12} style={{ marginTop: 40 }}>
						<h5>Time to regain your digital privacy</h5>
						<Row>
							<Col lg={24} md={24}>
								<p>
									Machine learning and deep learning are no longer sci-fi
									subjects. These technologies are constantly tweaking and
									adapting based on your browsing history with an ultimate
									goal of serving you <b>relevant</b> ads, derrived from
									your online activity.
								</p>
							</Col>
						</Row>

						<Divider style={{ margin: '5px 0' }}/>

						<h5>Say No to deceptive and manipulative ads</h5>
						<Row>
							<Col lg={24} md={24}>
								<p>
									With scandals such as Cambridge Analytica we now know for
									sure how our data is used against us in a manipulative
									manner in order to politically influence us or to change our
									perspective towards certain products, ideas or even
									beliefs. Knowing your browsing history they can target
									and manipulate you on a subliminal level. <b>DnsAdBlock</b>
									&nbsp;will completely deny their requests for showing ads or
									attempting to track you.
								</p>
							</Col>
						</Row>
					</Col>
				</Row>
			</Content>
		</Wrapper>;
	}
}

export class ForParentsCallout extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return <Wrapper {...this.props}>
			<Content className="callout">
				<h2>Stay in control of the information your children see online</h2>
				<p>
					Make <b>"inappropriate content"</b> a thing of the past
				</p>

				<p className={'secondary'}>
					The internet can be a scary place, especially for children. No small child
					should ever browse the internet without proper parental controls in place,
					without guidance or supervision. With DnsAdBlock you have a lot of control
					on the browsing experience of your child by enabling specific block lists
					and safe searches across different engines.
				</p>
			</Content>

			<Content className="features">
				<Row>
					<Col md={24} lg={12} style={{ textAlign: 'left' }}>
						<img
							src={Rules} alt="Customizable DNS rules" width={'90%'}
							style={{ margin: '0 auto' }}
						/>
					</Col>
					<Col md={24} lg={12} style={{ marginTop: 40 }}>
						<h5>Block ads, tracking, adult or social media</h5>
						<Row>
							<Col lg={24} md={24}>
								<p>
									We have well over <b>1.5M</b> domains
									in our filters which are grouped into <b>10+
									categories</b> including <b>ads, adult, tracking,
									gambling, gaming or social media</b>. You can
									block all of them with a single click. You can
									even enable or disable certain groups based on the
									hour of the day or day of the week.
								</p>
							</Col>
						</Row>

						<Divider style={{ margin: '5px 0' }}/>

						<h5>Some things cannot be unseen</h5>
						<Row>
							<Col lg={24} md={24}>
								<p>
									Even us, as adults, cannot resist the
									urge to click on certain links due to,
									sometimes, grotesque, repulsive or downright
									inappropriate content. Imagine the effect that
									such information can have on your children
									and their view of the world.
								</p>
							</Col>
						</Row>
					</Col>
				</Row>
			</Content>

			<Content className="callout">
				<h2>How much is too much?</h2>
				<p>
					Define <b>"allowed hours"</b> for certain websites <sup style={{
					fontSize      : 12,
					color         : '#666',
					textDecoration: 'underline',
				}}>COMMING SOON</sup>
				</p>

				<p className={'secondary'}>
					Let's not put too much of an accent on the word &quot;inappropriate&quot;.
					Maybe you want to define certain boundaries and allowed hours for certain
					activities like gaming or social media for example.
				</p>

				<p className={'secondary'}>
					With <b>dnsadblock</b> you can enable or disable groups of rules based on
					the <b>hour of the day</b> or <b>day of the week</b> (weekends can be
					special we know).
				</p>
			</Content>
		</Wrapper>;
	}
}

export class ForSchoolsCallout extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return <Wrapper {...this.props}>
			<Content className="callout">
				<h2>Keep your students focused and safe</h2>
				<p className={'secondary'}>
					Block and unblock content based on types, hour of the day
					or even day of the week. Redirect web searches to their&nbsp;
					&quot;safe&quot; variants for google, bing and youtube.
				</p>
			</Content>

			<Content className="features">
				<Row>
					<Col md={24} lg={12} style={{ textAlign: 'left' }}>
						<img
							src={Rules} alt="Customizable DNS rules" width={'90%'}
							style={{ margin: '0 auto' }}
						/>
					</Col>
					<Col md={24} lg={12} style={{ marginTop: 40 }}>
						<h5>Block ads, tracking, adult or social media</h5>
						<Row>
							<Col lg={24} md={24}>
								<p>
									We have well over <b>1.5M</b> domains
									in our filters which are grouped into <b>10+
									categories</b> including <b>ads, adult, tracking,
									gambling, gaming or social media</b> and you can
									block all of them with a single click.
								</p>
							</Col>
						</Row>

						<Divider style={{ margin: '5px 0' }}/>

						<h5>Block everything, allow just a few</h5>
						<Row>
							<Col lg={24} md={24}>
								<p>
									Do you want 100% control over the browsing experience?
									With Lockdown mode you can block everything&nbsp;
									<b>except</b> for the websites you whitelist.
								</p>
							</Col>
						</Row>
					</Col>
				</Row>
			</Content>

			<Content className="callout">
				<h2>Faster, easier and light on resources</h2>
				<p className={'secondary'}>
					It's time to get rid of that expensive hardware that is always in
					need for updates. We're offering a cloud based solution which you
					can enable and use right away with a simple DNS change.
				</p>
			</Content>
		</Wrapper>;
	}
}

export class LeaksCallout extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return <Wrapper {...this.props}>
			<Content className="callout">
				<h2>Prevent DNS leaks with our premium servers</h2>
				<p className={'secondary'}>
					Our software will update your interfaces and upgrade
					your DNS servers to a secure channel that proxies regular
					DNS traffic using a DNS-ver-https protocol which is hard
					to monitor since it appears as regular http traffic
				</p>
			</Content>

			<Content className="features">
				<Row>
					<Col md={24} lg={12} style={{ textAlign: 'left' }}>
						<img
							src={Rules} alt="Customizable DNS rules" width={'90%'}
							style={{ margin: '0 auto' }}
						/>
					</Col>
					<Col md={24} lg={12} style={{ marginTop: 40 }}>
						<h5>Block ads, tracking, adult or social media</h5>
						<Row>
							<Col lg={24} md={24}>
								<p>
									We have well over <b>1.5M</b> domains
									in our filters which are grouped into <b>10+
									categories</b> including <b>ads, adult, tracking,
									gambling, gaming or social media</b> and you can
									block all of them with a single click.
								</p>
							</Col>
						</Row>

						<Divider style={{ margin: '5px 0' }}/>

						<h5>Block everything, allow just a few</h5>
						<Row>
							<Col lg={24} md={24}>
								<p>
									Do you want 100% control over the browsing experience?
									With Lockdown mode you can block everything&nbsp;
									<b>except</b> for the websites you whitelist.
								</p>
							</Col>
						</Row>
					</Col>
				</Row>
			</Content>

			<Content className="callout">
				<h2>Faster, easier and light on resources</h2>
				<p className={'secondary'}>
					All filtering is done on our servers which is
					light on your resources and does not consume extra
					power. With a simple DNS change you can enjoy a safer
					and faster browsing experience.
				</p>
			</Content>
		</Wrapper>;
	}
}

export class ForOfficeCallout extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return <Wrapper {...this.props}>
			<Content className="callout">
				<h2>Let's talk about distractions<br/>and procrastination</h2>
				<p className={'secondary'}>
					In just 2 decades, the percentage of people
					reporting to procrastinate heavily at work
					&nbsp;<u>has increased from 5% to 26%</u>.
				</p>
				<p className={'secondary'}>
					Certain websites and apps are carefully designed to
					get as much of your attention as possible. At work,
					this leads to a drop in productivity and a much lower
					quality of work being done.
				</p>
			</Content>

			<Content className="features tight">
				<Row gutter={40}>
					<Col md={24} lg={12} style={{ textAlign: 'left' }}>
						<List
							size={'large'}
							loading={false}
							itemLayout="horizontal"
							bordered={true}
							style={{
								margin    : 0,
								background: 'white',
							}}
							dataSource={[{
								key  : 'ads',
								title: 'Block ads',
							}, {
								key  : 'tracking',
								title: 'Block tracking',
							}, {
								key  : 'smedia',
								title: 'Block social media',
							}, {
								key  : 'adult',
								title: 'Block adult',
							}, {
								key  : 'games',
								title: 'Block games',
							}, {
								key  : 'gambling',
								title: 'Block gambling',
							}, {
								key  : 'dating',
								title: 'Block dating',
							}]}
							renderItem={(item: any) => {
								return <List.Item
									key={item.key}
									actions={[<Switch defaultChecked={true}/>]}
								>
									<List.Item.Meta
										title={<b>{item.title}</b>}
									/>
								</List.Item>;
							}}
						/>
					</Col>
					<Col md={24} lg={12}>
						<h5>Block social media, adult, gaming and other categories with ease</h5>
						<Row>
							<Col lg={24} md={24}>
								<p>
									We have well over 1.5M domains
									in our filters which are grouped into 10+
									categories including ads, adult, tracking,
									gambling, gaming or social media and you can
									block all of them with a single click.
								</p>
							</Col>
						</Row>

						<h5>Don't like being restrictive? How about protective?</h5>
						<Row>
							<Col lg={24} md={24}>
								<p>
									DnsAdBlock is not just about restrictions.
									We can help you fight ads, tracking, malware
									or phishing. Keep your employees safe while
									browsing the internet.
								</p>
							</Col>
						</Row>
					</Col>
				</Row>
			</Content>

			<Content className="features tight">
				<Row gutter={40}>
					<Col md={24} lg={12} style={{ marginTop: 40 }}>
						<h5>Create custom rules and filters</h5>
						<Row>
							<Col lg={24} md={24}>
								<p>
									You can block and unblock any website you
									desire by using our filters form. The rules
									will be effective immediately.
								</p>
							</Col>
						</Row>
					</Col>

					<Col md={24} lg={12} style={{ textAlign: 'center' }}>
						<img alt={'create custom rules'} src={RulesForm} style={{
							border      : '1px solid #ccc',
							borderRadius: 6,
							width       : isMobile ? '100%' : 'auto',
						}}/>
					</Col>

				</Row>
			</Content>

			<Content className="features tight">
				<Row gutter={40}>
					<Col md={24} lg={12} style={{ textAlign: 'left' }}>
						<List
							size={'large'}
							loading={false}
							itemLayout="horizontal"
							bordered={true}
							style={{
								margin    : 0,
								background: 'white',
							}}
							dataSource={[{
								key  : 'lockdown',
								title: 'Lockdown mode',
							}]}
							renderItem={(item: any) => {
								return <List.Item
									key={item.key}
									actions={[<Switch defaultChecked={true}/>]}
								>
									<List.Item.Meta
										title={<b>{item.title}</b>}
										description={`In lockdown mode all requests 
										are denied except for those that are in
										 your whitelist.`}
									/>
								</List.Item>;
							}}
						/>
					</Col>

					<Col md={24} lg={12}>
						<h5>Block everything, allow just a few</h5>
						<Row>
							<Col lg={24} md={24}>
								<p>
									Do you want 100% control over the browsing experience?
									With Lockdown mode you can block everything&nbsp;
									<b>except</b> for the websites you whitelist.
								</p>
							</Col>
						</Row>
					</Col>

				</Row>
			</Content>

			<Content className="features tight" style={{ textAlign: 'center' }}>
				<h5>
					Keep a close eye on everything that is taking place
				</h5>
				<p style={{ marginBottom: 50, textAlign: 'center' }}>
					See general stats and actionable logs from your dashboard.<br/>Stay in control
					of the situation at all times and see for yourself how much work
					DnsAdBlock is doing underneath
				</p>

				<Row gutter={20}>
					<Col md={24} lg={12} style={{ marginBottom: 40 }}>
						<img alt={'DNS block ads logs'} src={StatsImg} style={{
							border: '1px solid #ccc',
							width : isMobile ? '100%' : 'auto',
						}}/>
					</Col>

					<Col md={24} lg={12}>
						<img alt={'DNS block ads categories'} src={StatsCategoriesImg} style={{
							border: '1px solid #ccc',
							width : isMobile ? '100%' : 'auto',
						}}/>
					</Col>
				</Row>
			</Content>

			<Content className="callout" style={{ marginTop: 100, marginBottom: 200 }}>
				<h2>Distractions and procrastination negatively impact your business</h2>
				<p className={'secondary'}>
					Your employees will not be able to meet goals or they will rush through
					tasks to avoid getting penalized.<br/>Both of these situations are bad
					for business and it is in your best interest yo make sure they won't
					be happening at your office.<br/><b>Not on your watch</b>!
				</p>
			</Content>
		</Wrapper>;
	}
}
