import * as React from 'react';

import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Row, Col, Button } from 'antd';

import Content from '@/components/content';
import Logo from '@/components/logo';


import { State as iRootState } from '../../../types';
import { iState as iPlansState, iModel as iPlanModel } from '@/state/models/plans';
import { iState as iProfileState } from '@/state/models/profile';
import PriceCard from './card';

import LoginBg from '@/static/images/login-bg.svg';

const Wrapper = styled.div`
	padding: 0 0 150px 0;
	background-image: url(${LoginBg});
	background-repeat: no-repeat;
	background-position: center 110px;
	background-size: 100%;

	.callout {
		background: transparent;
		h2 {
			text-align: center;
			font-size: 3.5rem;
			font-weight: 400;	
		}
		
		p {
			text-align: center;
			font-size: 1.5rem;
		}
	}
	
	.pricing {
		background: transparent;
			
	
		h2 {
			margin: 0;
			text-align: center;
			font-weight: 400;
			font-size: 2.5rem;
		}
		
		b.price {
			display: block;
			margin-top: 30px;
			font-weight: 800;
			font-size: 4.5rem;
			color: black;
			overflow: hidden;
		}
		
		.cycle-switch {
			margin: 20px 0 40px 0;
			text-align: center;
		}
		
		@media (max-width: 990px) {
			h2 {
				font-size: 1.5rem;
			}
		}
	}
`;

interface Props {
	plans: iPlansState;
	profile: iProfileState;
}

interface State {
	cycle: 'monthly' | 'bi-annually' | 'annually';
}


export class Pricing extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	state = {
		cycle: 'monthly' as 'monthly',
	};


	changePeriod(newPeriod: 'monthly' | 'bi-annually' | 'annually') {
		this.setState({ cycle: newPeriod });
	}

	subscribe = (plan: iPlanModel) => {
		const { profile } = this.props;

		if (profile.data.email) {
			navigate(`/app/profile`);
		} else {
			navigate(`/app/register?plan=${plan.id}&cycle=${this.state.cycle}`);
		}
	};

	render() {
		const { plans, profile, ...rest } = this.props;
		const { cycle } = this.state;

		return <Wrapper {...rest}>
			<Content className="callout">
				<Logo width={90} style={{
					margin   : '0 auto 40px auto',
					textAlign: 'center',
				}}/>
			</Content>

			<Content className="pricing">
				<h2>Ridiculously low pricing</h2>

				<div className="cycle-switch">
					<Button.Group>
						<Button
							type="primary"
							size={isMobile ? undefined : 'large'}
							ghost={String(this.state.cycle) != 'monthly'}
							onClick={() => this.changePeriod('monthly')}
						>
							Monthly
						</Button>
						<Button
							type="primary"
							size={isMobile ? undefined : 'large'}
							ghost={String(this.state.cycle) != 'bi-annually'}
							onClick={() => this.changePeriod('bi-annually')}
						>
							Bi-annually
						</Button>
						<Button
							type="primary"
							size={isMobile ? undefined : 'large'}
							ghost={String(this.state.cycle) != 'annually'}
							onClick={() => this.changePeriod('annually')}
						>
							Annually
						</Button>
					</Button.Group>
				</div>

				<Row gutter={32}>
					{(plans.data.results && plans.data.results.length) && plans.data.results.sort((a, b) => a.price_monthly - b.price_monthly).map(
						(plan, k) => {
							return <Col
								style={{ marginTop: 40 }} xs={24} md={12}
								lg={24 / plans.data.results.length}
								key={k}>
								<PriceCard cycle={cycle} onClick={this.subscribe} plan={plan}/>
							</Col>;
						})}
				</Row>
			</Content>
		</Wrapper>;
	}
}

const mapStateToProps = (state: iRootState) => {
	return {
		plans  : state.plans,
		profile: state.profile,
	};
};

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Pricing);

